import React from "react"
import logo from "../assets/logo_black.png"
import { auth } from "../firebase"
import "../styles/Header.css"

const Header = () => {
  return (
    <div className="header">
      <div className="header__left">
        <img src={logo} alt="ML6 company Logo" className="header__logo" />
        <h2 className="header__title">
          Projects <span className="header__title__sep">x</span> Tech Radar
        </h2>
      </div>
      <div className="header__right">
        <p className="header__signOut" onClick={() => auth.signOut()}>
          Sign out
        </p>
      </div>
    </div>
  )
}

export default Header
