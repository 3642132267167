import React from "react"
import "../styles/Search.css"

const Search = ({
  searchText,
  projects,
  setSearchText,
  setProjectsToShow,
  setSelectedProjectIndex,
}) => {
  const handleSearchText = (event) => {
    setSearchText(event.target.value)
    if (!event.target.value) {
      setProjectsToShow(projects)
    } else {
      setProjectsToShow(
        projects.filter((project) =>
          project.project_number
            .concat(" ", project.name, " ", project.customer)
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        )
      )
    }
    setSelectedProjectIndex(0)
  }

  return (
    <div className="search">
      <input
        type="text"
        placeholder="Search.."
        value={searchText}
        onChange={handleSearchText}
        className="search__bar"
      />
    </div>
  )
}

export default Search
