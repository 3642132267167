import { useEffect, useState, useRef } from "react"
import "./App.css"
import "./styles/color_definitions.css"
import Header from "./components/Header"
import Search from "./components/Search"
import Chapters from "./components/Chapters"
import Projects from "./components/Projects"
import Components from "./components/Components"

import { db, auth, provider } from "./firebase"

function App() {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [selectedChapters, setSelectedChapters] = useState([])
  const [projectsToShow, setProjectsToShow] = useState([])
  const [componentCheckboxes, setComponentCheckboxes] = useState([])
  const [allChapters, setAllChapters] = useState([])

  const projects = useRef([])
  const all_components = useRef([])
  const project_number = useRef("")

  // Hook for user login
  useEffect(() => {
    auth.onAuthStateChanged(() => {
      if (!auth.currentUser) {
        auth.getRedirectResult().then((result) => {
          if (result.user) {
            auth.updateCurrentUser(result.user)
          } else {
            auth.signInWithRedirect(provider)
          }
        })
      }
    })
  }, [])

  // Hook to do initial load for projects, components and chapters
  useEffect(() => {
    db.collection(process.env.REACT_APP_PROJECTS_COLLECTION)
      .get()
      .then((snapshot) => {
        snapshot.forEach(async (doc) => {
          projects.current.push({
            ...doc.data(),
            ...{ id: doc.data().project_number },
          })
        })
        projects.current = projects.current.reverse()
        setProjectsToShow(projects.current)
      })
    db.collection(process.env.REACT_APP_TECHRADAR_COMPONENTS_COLLECTION)
      .get()
      .then((snapshot) => {
        snapshot.forEach(async (doc) => {
          all_components.current.push({
            ...doc.data(),
            ...{ id: doc.data().id },
          })
        })
        let chapters = [
          ...new Set(all_components.current.map((item) => item.chapter)),
        ]
        setAllChapters(chapters)
        setSelectedChapters(chapters)
      })
  }, [])

  // Hook that updates selected components when projects change
  useEffect(() => {
    const check_arr = new Array(all_components.current.length).fill(false)
    if (projectsToShow.length !== 0) {
      project_number.current =
        projectsToShow[selectedProjectIndex].project_number

      let components = []
      db.collection(
        `${process.env.REACT_APP_PROJECS_WITH_COMPONENTS_COLLECTION}/${project_number.current}/assigned_components`
      )
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            components.push(doc.data().name)
          })
          all_components.current.forEach((element, i) => {
            if (components && components.includes(element.name)) {
              check_arr[i] = true
            }
          })
          setComponentCheckboxes(check_arr)
        })
    }
  }, [selectedProjectIndex, projectsToShow])

  return (
    auth.currentUser && (
      <div className="app">
        <Header />
        <div className="app__body">
          <div className="app__bodyLeft">
            <Search
              searchText={searchText}
              projects={projects.current}
              setSearchText={setSearchText}
              setProjectsToShow={setProjectsToShow}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
            <Projects
              projects={projectsToShow}
              selectedProjectIndex={selectedProjectIndex}
              setSelectedProjectIndex={setSelectedProjectIndex}
            />
          </div>
          <div className="app__bodyRight">
            <Chapters
              chapters={allChapters}
              selectedChapters={selectedChapters}
              setSelectedChapters={setSelectedChapters}
            />
            <Components
              all_components={all_components.current}
              selectedChapters={selectedChapters}
              componentCheckboxes={componentCheckboxes}
              setComponentCheckboxes={setComponentCheckboxes}
              project_number={project_number.current}
              chapters={allChapters}
            />
          </div>
        </div>
        <div className="app_footer">
          <div className="app__footer__centerMessage">
            For more information regarding the Tech Radar Components, please
            check out the{" "}
            <a href="https://tech-radar.internal.ml6.eu/"> ML6 Tech Radar</a>.
            <br></br>
            In order to see the list of projects for a certain Tech Radar
            Component, please check out the{" "}
            <a href="https://use-cases.internal.ml6.eu/">Use Case Library</a>.
          </div>
        </div>
      </div>
    )
  )
}

export default App
