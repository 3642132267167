import React from "react"
import "../styles/Chapters.css"

const Chapters = ({ chapters, selectedChapters, setSelectedChapters }) => {
  const handleInputChange = (e) => {
    if (e.target.checked) {
      setSelectedChapters(selectedChapters.concat([e.target.name]))
    }
    if (!e.target.checked) {
      setSelectedChapters(selectedChapters.filter((c) => c !== e.target.name))
    }
  }

  return (
    <div className="chapters">
      <form onChange={handleInputChange}>
        {chapters.map((chapter, i) => (
          <label
            className={`chapter__label chapter__${chapter
              .replace(/\s+/g, "")
              .toLowerCase()}`}
            key={i}
          >
            <input type="checkbox" defaultChecked="True" name={chapter} className="chapter__checkbox"/>
            {chapter}
          </label>
        ))}
      </form>
    </div>
  )
}

export default Chapters
