import React from "react"
import { NotificationContainer, NotificationManager } from "react-notifications"
import "../styles/Components.css"
import "react-notifications/lib/notifications.css"

import { db } from "../firebase"

const Components = ({
  all_components,
  selectedChapters,
  componentCheckboxes,
  setComponentCheckboxes,
  project_number,
  chapters,
}) => {
  const handleChange = (e, i, component_id) => {
    // set booleans for components
    let cpy_componentCheckboxes = [...componentCheckboxes]
    cpy_componentCheckboxes[i] = !componentCheckboxes[i]
    setComponentCheckboxes(cpy_componentCheckboxes)

    let doc_ref = db
      .collection(
        `${process.env.REACT_APP_PROJECS_WITH_COMPONENTS_COLLECTION}/${project_number}/assigned_components`
      )
      .doc(component_id)

    if (cpy_componentCheckboxes[i]) {
      doc_ref.set({
        name: all_components[i].name,
        timestamp: Date.now(),
      })
    } else {
      doc_ref.delete()
    }

    NotificationManager.success("Update Completed", "Success", 2000)
  }

  const ChapterContainer = ({ chapter }) => {
    return (
      <form className="component__form">
        {all_components.map((component, i) => (
          <div
            className={`component__box component__${chapter
              .replace(/\s+/g, "")
              .toLowerCase()} ${
              !selectedChapters.includes(component.chapter) ||
              component.chapter !== chapter
                ? "component__hidden"
                : ""
            }`}
            key={i}
          >
            <input
              type="checkbox"
              className="component__checkbox"
              checked={componentCheckboxes[i]}
              name={component.name}
              onChange={(e) => handleChange(e, i, component.id)}
            />
            <label>{component.name}</label>
          </div>
        ))}
      </form>
    )
  }

  return (
    <div className="components">
      {chapters.map((chapter, i) => (
        <ChapterContainer chapter={chapter} key={i} />
      ))}
      <NotificationContainer />
    </div>
  )
}

export default Components
