import React from "react"
import "../styles/Projects.css"

const Projects = ({
  projects,
  selectedProjectIndex,
  setSelectedProjectIndex,
}) => {
  const NumberToIndex = (project_number) => {
    return projects.map((p) => p.project_number).indexOf(project_number)
  }

  const handleKeyDown = (e) => {
    if (e.code === "ArrowUp" && selectedProjectIndex > 0) {
      e.preventDefault() // prevent scroll in div
      setSelectedProjectIndex(selectedProjectIndex - 1)
    }
    if (e.code === "ArrowDown" && selectedProjectIndex < projects.length - 1) {
      e.preventDefault() // prevent scroll in div
      setSelectedProjectIndex(selectedProjectIndex + 1)
    }
  }

  const handleClick = (e) => {
    let project_number = e.target.innerText.substring(0, 4)
    setSelectedProjectIndex(NumberToIndex(project_number))
  }

  const Project = ({ project_number, name }) => {
    let cl = ""
    projects[selectedProjectIndex].project_number === project_number
      ? (cl = "selected")
      : (cl = "")
    return (
      <li className={"project__line " + cl} onClick={handleClick}>
        {project_number} - {name}
      </li>
    )
  }

  return (
    <div className="projects" onKeyDown={handleKeyDown} tabIndex={-1}>
      <ul>
        {projects.map((project) => (
          <Project
            name={project.name}
            key={project.id}
            project_number={project.project_number}
          />
        ))}
      </ul>
    </div>
  )
}

export default Projects
